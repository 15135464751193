import './App.css';
import Header from './components/Header';
import HeroSection from'./components/HeroSection';
import Footer from './components/Footer';
import Content from './components/MainContent';

function App() {
  return (
    <div className="App">
      <header>
        <Header/>
      </header>
  
<section className="hero">
<HeroSection/>
<div className="content-box">
 <Content/>
 </div>
</section>
      <footer>
        <div>
          <div className="contact-us">
            {/* ContactUs form here */}
          </div>

          <div className="contact-info">
            <Footer/>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
