import React from 'react';
import data from '../data.json';

function  MainContent() {
 
    return (
      <div className="main-content">

<div className="ukr-box">
    <div className="ukr-intro" >
        <h1 className="title"> {data[0].title}</h1>
        <h3 className="subtitle">{data[0].subtitle}</h3>
        <p className="description">{data[0].description}</p>
    </div>

    <div className="ukr-services">
        <h1 className="services"> {data[0].services} </h1>
        <ul>         
            <li>{data[0].list1}</li>
            <li>{data[0].list2}</li>
            <li>{data[0].list3}</li>
            <li>{data[0].list4}</li>
            <li>{data[0].list5}</li>
        </ul>
    </div>

    <div className="ukr-price">
        <h3>Вартість послуг: </h3>
        <p className="price"> &nbsp; {data[0].price} євро за годину + ПДВ </p>

    </div>

</div>

<div className="fi-box">
    <div className="fi-intro" >
        <h1 className="title"> {data[1].title}</h1>
        <h3 className="subtitle">{data[1].subtitle}</h3>
        <p className="description">{data[1].description}</p>
    </div>

    <div className="fi-services">
        <h2 className="services"> {data[1].services} </h2>
        <ul>         
            <li>{data[1].list1}</li>
            <li>{data[1].list2}</li>
            <li>{data[1].list3}</li>
            <li>{data[1].list4}</li>
            <li>{data[1].list5}</li>
        </ul>
    </div>

    <div className="fi-price">
        <h3>Palvelun hinta:</h3>
        <p className="price"> &nbsp; {data[1].price} euroa/tunti + ALV </p>

    </div>

</div>
    </div>
    )
  
}

export default MainContent;