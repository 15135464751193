import React from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faWhatsapp,faFacebook} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCopyright} from '@fortawesome/free-regular-svg-icons';
import data from '../data.json';
import logoIMG from '../img/logo-footer.png'

function Footer() {
  return (
    <div className="footer-box">
   <div className="footer-info-box">

   <div className="footer-info">

   <div className="footer-title">
  <h2>{data[2].title}</h2>
 </div>

   <div className="contact-info-box">
 
    <div className="email-box" onClick={() => window.location = 'mailto:contact@kotkantilitoimisto.fi'}>
      <div className="email-logo"><FontAwesomeIcon icon={faEnvelope}/></div>
      <div className="email">{data[2].email}</div>
      </div>
   
    <div className="phone-box">
      <div className="whatsup-logo"><FontAwesomeIcon icon={faWhatsapp} /></div>
      <div className="phone">{data[2].phone}</div>
    </div>
  

    <div className="footer-fb-box">
    <a href="https://www.facebook.com/profile.php?id=61557350341889"
        >
      <FontAwesomeIcon icon={faFacebook} className="fb-footer"/>
      </a>
      </div>
   </div>
</div>


   <div className="footer-logo-box">
   <img src={logoIMG} className="footer-logo" alt="Logo" />
   </div>

</div>
<div className="copyright-box">
<div className="copyright-logo"><FontAwesomeIcon icon={faCopyright} /></div>
      <div className="copyright">{data[2].copyright}</div>


</div>
</div>

  )
}

export default Footer